
#stationModal {
  .modal-header {
    color: #364657;
    border: 0;
    padding-bottom: 0;

    p {
      margin: 15px 0 0 0;
    }

  }
  .modal-footer {
    border: 0;
    text-align: left;
    padding-top: 0;
  }

  .form-group {
    position: relative;
    min-height: 56px;
  }

  .modal-header .close {
    position: absolute;
    right: -45px;
    top: 3px;
    opacity: 1;
    color: #fff;
    transition: opacity .5s ease-in-out;

    &:hover {
      opacity: 0.5;
    }
  }
}

.form-request {
  .well {
    @include clearfix();
  }

  .form-group {
    background: #fff;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 5px;
    position: relative;
    @include clearfix();
    height: 56px;
  }
  .controls {
    height: 56px;

  }
  .error-block {
    color: #a94442;
  }

  textarea.form-control {
    padding-top: 20px;
  }

  label {
    font-weight: normal;
    color: #959da6;
    position: absolute;
    top: 18px;
    left: 16px;
  }

  input[type='text'],
  input[type='email'],
  textarea {
    background: transparent;
  }

  input[type='text'],
  input[type='email'],
  textarea {
    position: absolute;
    z-index: 555;
    height: 56px;
    padding-bottom: 0;
  }

  #div_id_Station_address {
    height: 120px;
  }

  textarea {
    height: 120px;
  }

  .submit-group {
    height: 60px;
    margin-top: 20px;
  }

  .radio label {
    position: static;
  }
}