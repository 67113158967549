html, body {
  min-width: 960px !important;
}

.container {
  width: 960px !important;
  min-width: 960px !important;
  max-width: 960px !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

a {
  text-decoration: underline;
}

.header {
  margin: 0 -10px;

  .logo {
    text-indent: -3000px;
    background: url('../images/logo.png') no-repeat;
    @include size(290px, 81px);

    a {
      display: block;
      @include size(290px, 81px);
    }
  }

  .phone {
    margin-top: 32px;
    line-height: 18px;

    a {
      text-decoration: none;
      color: #a5a5a5;
      border-bottom: 1px dashed #a5a5a5;
      font-size: 90%;
      &:hover {
        color: darken(#a5a5a5, 15%);
      }
    }

    span.phone {
      font-size: 26px;
    }

    span.dot {
      @include size(22px, 21px);
      background: url('../images/dot.png') no-repeat 2px 1px;
      display: inline-block;
      padding: 2px;
    }
  }
}

.navbar-collapse {
  padding-left: 0;
}

.navbar {
  position: relative;
  z-index: 23;
  margin: 14px -5px 0;
  border-radius: 0;
  border: 0;
  min-width: 960px;
  /*background: linear-gradient(to right, #ffffff 5%, #364657 5%, #364657 5%, #364657 95%, #ffffff 95%);*/

  & > li {
    float: left;
  }

  li {
    background: $navbar-default-bg;
    a {
      color: #fff;
      padding: 13px 1px;
      text-decoration: none;
      /*transition: all .1s ease-in;*/
    }

    a:after {
      position: absolute;
      width: 1px;
      height: 25px;
      top: 10px;
      right: 1px;
      /*border-right: 1px solid red;
      box-shadow: 1px 0 0 yellow;
      content: "";*/
    }

    &.active, &.open,
    a:hover, a:focus,
    a:hover:after {
      content: none;
      color: #fff;
      background: darken($navbar-default-bg, 6.5%);
    }
  }
}

.nav-justified {
  width: 100%;
  min-width: 100%;
  font-size: 13px;

  & > li:first-child {
    border-radius: 8% 0 0 8% / 100% 0 0 100%;
    a:hover, a:focus {
      border-radius: 8% 0 0 8% / 100% 0 0 100%;
    }
  }

  & > li:hover:after,
  & > li:hover:before,
  & > li:first-child:after,
  & > li:first-child:before,
  & > li.active:after,
  & > li.active:before,
  & > li.open:after,
  & > li.open:before,
  & > li.next:after,
  & > li.next:before {
    opacity: 0;
  }

  & > li:before {
    /*transition: all .1s ease-in;*/
    opacity: 1;
    position: absolute;
    display: block;
    content: ' ';
    margin-top: 12px;
    height: 22px;
    width: 1px;
    background: #101419;
  }

  & > li:after {
    /*transition: all .1s ease-in;*/
    opacity: 1;
    position: absolute;
    display: block;
    content: ' ';
    height: 22px;
    width: 1px;
    margin-top: 12px;
    top: 0;
    left: 1px;
    background: #5e6b79;
  }

  & > li:last-child {
    border-radius: 0 8% 8% 0 / 0 100% 100% 0;
    a {
      padding-left: 35px;
    }

    img {
      position: absolute;
      left: 10px;
      top: 10px
    }

    a:hover, a:focus {
      border-radius: 0 8% 8% 0 / 0 100% 100% 0;
    }
  }

  /*& > li {
    border-left: 1px solid #5e6b79;
    border-right: 1px solid #101419;
  }*/
}

.intro {
  height: 445px;
  padding: 20px 0;
  position: relative;
  margin-top: -23px;

  h2 {
    font-family: $font-family-sans-serif;
    font-weight: 400;
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 42px;
  }

  ul > li {
    margin: 10px 0;
  }

  ul.pros-list {
    margin-bottom: 40px;
  }

  &.intro-main {
    background: #f6f5f3 url('../images/slides/1.jpg') no-repeat 50% 0;
  }

  &.intro-monitor {
    background: #f8fafc url('../images/slides/2.jpg') no-repeat 50% 0;

    h2 {
      font-size: 36px;
      margin-bottom: 10px;
    }

    .pros-block .pros-list li {
      margin: 15px 0;
    }
  }

  &.intro-roads {
    background: #fbf8f4 url('../images/slides/3.jpg') no-repeat 50% 0;
  }

  &.intro-sim {
    background: #f7fbfd url('../images/slides/4.jpg') no-repeat 50% 0;

    h2 {
      margin-top: 50px;
    }
  }
}

.menu {
  .menu-block + .menu-block:hover,
  .menu-block + .menu-block.active {
    border: 1px solid #cfcfcf;
    border-radius: 5px;
  }

  .menu-block:hover + .menu-block {
    border-left-color: transparent;
  }

  .menu-block {
    color: #364657;
    margin-left: -1px;
    margin-top: 5px;

    border: 1px solid transparent;
    border-right-color: #cfcfcf;
    height: 95px;

    text-decoration: none;

    background: url('../images/circle.png') no-repeat 17px 8px;
    transition: opacity .5s ease-in-out;

    &:hover,
    &.active {
      background: url('../images/yellow.png') no-repeat 17px 8px;
    }

    &:first-child:hover,
    &:first-child.active {
      border: 1px solid #cfcfcf;
      border-radius: 7px;
    }

    &:last-child {
      border: 1px solid transparent;
    }

    & > span {
      padding-left: 90px;
      padding-top: 22px;
      display: block;
      font-size: 17px;
      font-weight: bold;
      height: 95px;
    }

    &.menu-block-mon > span {
      background: url('../images/pics/mon.png') no-repeat 25px 24px;
    }

    &.menu-block-card > span {
      background: url('../images/pics/card.png') no-repeat 20px 26px;
    }

    &.menu-block-road > span {
      background: url('../images/pics/road.png') no-repeat 25px 26px;
    }

    &.menu-block-sim > span {
      background: url('../images/pics/sim.png') no-repeat 20px 29px;
    }
  }
}

.map {
  color: #364657;
  margin-top: 55px;

  #yandex-map {
    width: 940px;
  }
}

.content-block {
  margin: 40px 0;

  &.about {
    h3 {
      margin-top: 40px;
    }

    ul {
      li {
        margin: 15px 0;
      }
    }
  }

  &.first-content-block {
    h2.page-header {
      border-bottom: 0;
    }
  }

  h2.page-header {
    margin-top: 0;
    text-transform: uppercase;
    font-size: 36px;
    border-bottom-color: #bbb;
    padding-bottom: 15px;
  }
}

.country-list {
  .thumbnail {
    background: #f0f3f4;
  }

  .thumbnail .caption {
    font-size: 13px;
    padding: 4px;

  }

  .thumbnail a {
    text-decoration: none
  }

  .thumbnail span {
    text-align: center;
    display: block;
    float: left;
    margin-right: 10px;
    margin-top: 3px;
    margin-left: 10px
  }

}

.geography {
  h4 {
    margin: 0 0 27px 0;
  }
}

.color-gray {
  color: $gray-light;
}

.text-small {
  font-size: 13px
}

#slider,
.text-scroller {
  position: relative;
  padding: 0 60px;

  #slider-prev {
    position: absolute;
    top: 20px;
    left: 0;

    background: url('../images/larr.png') no-repeat;
    width: 49px;
    height: 49px;
    text-indent: -3000px;
    display: block;
    overflow: hidden;
    opacity: .5;
    transition: opacity .5s ease-in-out;
    cursor: pointer;

    &:hover.inactive {
      opacity: .5;
      cursor: default;
    }

    &:hover {
      opacity: 1;
    }
  }

  #slider-next {
    position: absolute;
    top: 20px;
    right: 0;

    background: url('../images/rarr.png') no-repeat;
    width: 49px;
    height: 49px;
    text-indent: -3000px;
    display: block;
    overflow: hidden;
    opacity: .5;
    transition: opacity .5s ease-in-out;

    cursor: pointer;

    &:hover.inactive {
      opacity: .5;
      cursor: default;
    }

    &:hover {
      opacity: 1;
    }
  }

  li > img {
    filter: grayscale(100%);
    opacity: .5;
    transition: opacity .5s ease-in-out;

    &:hover {
      cursor: pointer;
      filter: none;
      opacity: 1;
    }
  }
}

.footer-wrapper {
  border-top: 3px solid #eaeaea;
  min-width: 960px !important;
}

.footer {
  border-top: 3px solid #c0c0c0;
  background: url('../images/footer-bg.png') repeat-x;
  height: 250px;

  .logo {
    @include size(209px, 53px);
    background: url('../images/logo-transparent.png') no-repeat;
    overflow: hidden;
    text-indent: -3000px;
    margin-top: 30px;

    a {
      display: block;
      @include size(209px, 53px);
    }
  }

  .phone {
    font-size: 21px;
    margin-left: 40px;
    margin-top: 20px;
    color: #727272;
  }

  .callback {
    font-size: 13px;
    margin-left: 40px;

    a {
      color: #a5a5a5;
      text-decoration: none;
      border-bottom: 1px dashed #a5a5a5;

      &:hover {
        color: darken(#a5a5a5, 15%);
        border-bottom: 1px dashed darken(#a5a5a5, 15%);
      }
    }

  }
}

.copyright {
  background: #c7c7c7;
  padding: 15px 0;
  color: #475462;
}

ul.footer-nav {
  margin: 15px 0;

  & > li {
    margin: 5px 0;
  }

  & > li > ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li:before {
      content: "";
      border-color: transparent #525960;
      border-style: solid;
      border-width: 0.35em 0 0.35em 0.45em;
      display: block;
      height: 0;
      width: 0;
      left: -1em;
      top: 0.9em;
      position: relative;
    }

    li {
      padding-left: 1em;
      margin: 2px 0;
    }

    li > a {
      color: #2f2f2f;
      text-decoration: none;
      &:hover {
        color: lighten(#2f2f2f, 30%)
      }
    }
  }

  & > li > a {
    font-family: $headings-font-family;
    font-size: 19px;
    text-transform: uppercase;
    color: #2f2f2f;
    text-decoration: none;
    &:hover {
      color: lighten(#2f2f2f, 30%)
    }
  }

}

.pros-block {
  h2 {
    font-weight: 300;
    color: #263444;
    margin-top: 22px;
  }

  .pros-list {
    li {
      margin: 20px 0;
      color: #364657;
      padding-left: 30px;
      background: url('../images/check.png') no-repeat 0 2px;
    }
  }
}

#static-menu {
  position: fixed;
  background-color: #364657;
  color: #f5f6f6;

  width: 100%;
  top: 0;
  height: 60px;
  z-index: 666;

  padding: 9px 0 12px 0;

  h2 {
    margin: 5px 0 0;
  }
}

.icon {
  &.icon-phone {
    background: url('../images/phone.png') no-repeat;
    width: 21px;
    height: 21px;
    display: inline-block;
  }

  &.icon-skype {
    background: url('../images/skype.png') no-repeat;
    width: 22px;
    height: 21px;
    display: inline-block;
    vertical-align: text-bottom;
  }
}

h3.phone {
  font-size: 28px;
  font-family: $font-family-sans-serif;
  font-weight: 300;
}

.contacts {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.page-header {
  margin-top: 35px;
}

.page-header.no-border {
  border-bottom: 0;
  vertical-align: bottom;
}

.graph {
  background: url('../images/graph.png') no-repeat;
  width: 950px;
  height: 503px;
  .graph-items {
    margin-top: 60px;
  }
  .graph-items li {
    margin-bottom: 56px;
  }
}

.monitor {
  h3 {
    font-family: $font-family-sans-serif;
    font-weight: 300;
    font-size: 26px;
  }
}

.monitor-footer {
  margin-bottom: 40px;

  .btn-default {
    margin-top: 25px;
  }
}

.hover-blocks {
  a {
    display: block;
    width: 460px;
    height: 323px;
    text-decoration: none;
  }

  div.overlay {
    width: 460px;
    height: 323px;
    background: rgba(0, 0, 0, .2);
    transition: background .5s ease-in-out;
    padding: 250px 0 0;
    text-align: center;
    position: relative;

    span {
      display: none;
      animation: fadein 1s;
    }

    h3 {
      animation: fadein .5s;
      width: 460px;
      text-align: center;
      position: absolute;
      top: 10px;
      color: #fff;
    }

    &:hover {
      span {
        display: inline;
      }

      h3 {
        display: block;
      }

      background: rgba(0, 0, 0, .75);
    }
  }

  .block-types {
    background: url('../images/roads/1.jpg');
    width: 460px;
    height: 323px;
  }

  .block-maps {
    background: url('../images/roads/2.jpg');
    width: 460px;
    height: 323px;
  }

  .block-ts {
    background: url('../images/roads/3.jpg');
    width: 460px;
    height: 323px;
  }

  .block-info {
    background: url('../images/roads/4.jpg');
    width: 460px;
    height: 323px;
  }

  .block-2-row {
    margin-top: 20px;
  }
}

.nav-pills {
  a {
    text-decoration: none;
    color: #364657;
  }
}

.question {
  border-top: 1px solid #dfe1e3;
  padding: 25px 0;
  & > a {
    border-bottom: 1px dashed $link-color;
    text-decoration: none;
    font-size: 18px;

    &:hover {
      color: #3c6991;
      border-bottom: 1px dashed #3c6991;
    }
  }

  .answer {
    margin: 15px 0 0;
    padding-left: 30px;
    display: none;
    animation: fadein .5s;
  }

  .answer.showed {
    display: block;
  }

  &:last-child {
    border-bottom: 1px solid #dfe1e3;
  }
}

.jcarousel {
  position: relative;
  overflow: hidden;
}

.jcarousel ul {
  width: 20000em;
  position: relative;

  /* Optional, required in this case since it's a <ul> element */
  list-style: none;
  margin: 0;
  padding: 0;
}

.jcarousel li {
  /* Required only for block elements like <li>'s */
  float: left;
}

.text-scroller {
  padding: 0 30px;
  #slider-prev {
    background: url('../images/larr_active.png') no-repeat;
    filter: grayscale(100%);
    width: 12px;
    height: 18px;
    top: 10px;
    left: 10px;
    opacity: 1;

    &:hover {
      filter: none;
    }
  }

  #slider-next {
    background: url('../images/rarr_active.png') no-repeat;
    top: 10px;
    right: 10px;
    filter: grayscale(100%);
    width: 12px;
    height: 18px;
    opacity: 1;

    &:hover {
      filter: none;
    }
  }
}

.well {
  box-shadow: none;
  border: none;
}

.variables {
  margin-bottom: 20px;

}

.columns {
  background: url('../images/ruller.png') no-repeat 50px 28px;
  height: 250px;
  padding-left: 40px;

  .col-xs-4 {
    width: 22%;
  }
}

.column {
  height: 240px;

  position: relative;

  .ticker {
    width: 100%;
    background: #364657;
    position: absolute;
    bottom: 0px;
    /*height: 150px;*/
  }

  .caption {
    position: absolute;
    bottom: -50px;
    width: 64px;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
  }

  &.berlio {
    .ticker {
      /*height: 100px;*/
      background: #ffdd2e;
    }

    .caption {
      left: -8px;
      width: 80px;

    }
  }

  .cost {
    color: #77808b;
    font-weight: normal;
    font-size: 11px;
  }
}

#div_id_group_n .controls {
  margin-left: 20px;
}

#route-builder {
  margin-top: 40px;
}

thead {
  background: #364657;
  color: #fff;
}

ul.stacked-list {
  margin: 50px 0;

  li {
    float: left;
  }

  li.active a {
    background: #74a3c7;
    border: 1px solid #74a3c7;
    color: #fff;
  }
  a {
    text-decoration: none;
    padding: 8px 20px;
    border: 1px solid #b9d1e3;
    border-radius: 15px;
  }

  li:first-child a {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  li:last-child a {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.btn-remove-control {
  position: absolute;
  right: 10px;
  top: 16px;
  background: url('../images/basket.png') no-repeat;
  width: 14px;
  height: 14px;
  padding: 5px;

  opacity: .5;

  &:hover {
    opacity: 1;
  }
}

#add-via {
  font-size: 13px;
}

.btn-reset,
#btn-restart {
  margin-left: 10px;
}

.modal-view {
  margin-left: 20px;
}

.table > thead > tr > th {
  vertical-align: top;
}

table.table-rosberlio {

  margin-top: 40px;

  & > thead > tr > th,
  & > thead > tr > td,
  & > tbody > tr > th,
  & > tbody > tr > td,
  & > tfoot > tr > th,
  & > tfoot > tr > td {
    border: 0;
  }

  thead > tr > th.active {
    background: #ffdd2e;
    color: #364657;
  }

  tbody > tr > td.active {
    background: rgba(255, 221, 45, .25);
  }
}

.pagination > li > a, .pagination > li > span {
  border: 0;
}

.form-request {
  h2.first {
    margin-top: 20px;
  }
  h2 {
    margin-bottom: 30px;
    margin-top: 50px;
  }
}

.round-block {
  margin: 40px 0 60px 0;

  .round-left {
    position: relative;
    padding-left: 90px;
    background: url('../images/yellow.png') no-repeat;
    height: 78px;
    min-height: 78px;
    display: inline-block;

    .number {
      font-size: 26px;
      position: absolute;
      left: 33px;
      top: 20px;
    }
  }
}

.asteriskField {
  margin-left: 5px;
}

.news-item-wrapper {
  /*height: 120px;
  min-height: 120px;
  max-height: 120px;
  */
  overflow: hidden;
  /*border-bottom: 1px solid #363432;*/
  /*margin-bottom: 20px;*/
  padding: 15px 0;
  position: relative;

  p.view-more {
    margin: 0;
  }

  .btn {
    position: absolute;
    left: 15px;
    bottom: 20px;
  }

  .datetime {
    color: #303e4e;
    font-size: 12px;
  }

  .title {
    font-weight: 800;
    color: #303e4e;
  }

  .title > a {
    text-decoration: none;
    color: #303e4e;
    &:hover {
      color: darken(#303e4e, 10%);
    }
  }

  .news-text-wrapper {
    min-height: 70px;
    height: 70px;
    overflow: hidden;
    margin-bottom: 10px;
  }
}

.car-table td {
  vertical-align: middle;
}

.btn,
input[type="submit"]:focus {
  outline: none;
}

li.dropdown:hover {
  background: #293643;
}

.dropdown-menu {
  background: #293643;
  border: 0;
  margin: 0;

  border-top-left-radius: 0;
  border-top-right-radius: 0;

  li.active,
  li.open,
  li a:hover,
  li a:focus,
  li a:hover:after {
    background: #364657;
  }
  li {
    background: #293643;
  }

  li > a {
    padding: 13px 20px;
  }

  a:hover {
    background: #364657;
  }
}

ul.nav li.dropdown:hover ul.dropdown-menu {
  display: block;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background: #364657;
}

.border-right1 {
  border-right: 1px solid #e4e4e4;
}

.border-right2 {
  border-right: 1px solid #ffffff;
}

.btn-plus {
  margin-left: 10px;
}

.btn-minus {
  margin-right: 10px;
}

.btn-plus,
.btn-minus {
  border: 1px solid #94bae2;
  border-radius: 4px;
}

#number {
  color: #536371;
}

.table-map {
  max-width: 400px;
  td {
    padding: 3px !important;
  }
}