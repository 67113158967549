
a.btn {
  text-decoration: none;
}

.btn {
  border-radius: 50px;
  font-size: 14px;
  transition: background .5s;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.btn.btn-default {
  padding: 10px 25px;
}
